import React, { useRef, useEffect, useState } from 'react';
import { usePubSub, useMeeting } from '@videosdk.live/react-sdk';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useChat from 'libs/hooks/useChat';
import Button from 'components/Button';
import Png from 'components/Png';
import AppDrawer from 'components/molecules/app-drawer';
import { EVENT_PAGE_NAVIGATION_ITEM, EVENT_TYPE_IFRAME } from 'libs/constant';
import { useGlobalConfigStore } from 'zustandStore';
import { EVENT_PUBSUB } from 'libs/constant';
import notificationSound from 'assets/audio/notification-sound.mp3';
import Left from './left';
import CloseDrawer from 'components/molecules/close-drawer';
import { getFullDatetime } from 'libs/date';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import { sendMessage } from 'libs/message';
import { getQueryParams } from 'libs/url';
import { DRAWER_TYPE } from 'containers/video/chat-view/constants';
import useTracking from 'libs/hooks/useTracking';
// import { getVirtualBackgroundUrl, isUseVirtualBackground } from 'libs/virtual-background';
// import useMediaStream from 'libs/hooks/useMediaStream';
import { VirtualBackgroundProcessor } from '@videosdk.live/videosdk-media-processor-web';
// import { doctorCameraConfig, patientCameraConfig } from 'libs/video';
// import { useMeetingAppContext } from 'MeetingAppContextDef';
import useIsMobileScreen from 'libs/hooks/useIsMobileScreen';

const Header = ({
  showHeader,
  showVideo,
  setShowVideo,
  showToggle,
  showChat,
  setShowChat,
  showInfo,
  setShowInfo,
  showFullChat,
  setShowFullChat,
  showBackButton,
  isSessionEnd,
  recipientName,
  recipientPhoto,
  preMeetingScreen = null,
  setIsUnread,
  isUnread,
  isMeetingStarted
}) => {
  const {
    value: {
      appointment: { appointmentNumber, isODDConsultation },
      doctor,
      patient,
      isDoctor,
      isShowAppDrawer
    },
    action: { saveChat, handleShowAppDrawer }
  } = useChat();
  const tracking = useTracking({
    from: 'Header'
  });
  const { t } = useTranslation();
  // const { getVideoTrack } = useMediaStream();

  const { meeting, localWebcamOn, localMicOn } = useMeeting();
  // const { toggledStates, selectedWebcam } = useMeetingAppContext();
  const isPatientJoinTheCall = useGlobalConfigStore(state => state.isPatientJoinTheCall);
  const audioPlayer = useRef(null);
  const meetingSdk = useMeeting();

  const videoProcessor = useRef(null);
  const isMobile = useIsMobileScreen();

  const [toggleCamera, setToggleCamera] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(DRAWER_TYPE.SCHEDULE_APPOINTMENT);

  const playAudio = () => {
    audioPlayer.current.play().catch(error => console.log('error', error));
  };
  const { publish, messages } = usePubSub(EVENT_PUBSUB.CHAT, {
    onMessageReceived(message) {
      try {
        const parsedMessage = JSON.parse(message.message);
        const isParticipantDoctor = parsedMessage.sender.includes('doctor');
        if ((isDoctor && !isParticipantDoctor) || (!isDoctor && isParticipantDoctor)) {
          playAudio();
        }
      } catch (error) {
        console.log('Error occured', error);
      }
    }
  });

  // const processVideoStream = useCallback(
  //   async stream => {
  //     if (isUseVirtualBackground(isDoctor)) {
  //       if (!videoProcessor.current.ready) {
  //         await videoProcessor.current.init();
  //       }
  //       const config = {
  //         type: 'image',
  //         imageUrl: getVirtualBackgroundUrl()
  //       };
  //       const processedStream = await videoProcessor.current.start(stream, config);
  //       return processedStream;
  //     } else {
  //       return stream;
  //     }
  //   },
  //   [isDoctor]
  // );

  const showSideBar = isMobile ? showInfo || showChat : (showVideo && showChat) || showInfo;
  const photo = recipientPhoto && !isDoctor ? recipientPhoto : 'doctor-profile-default';

  // const getPictureInPictureMode = () => {
  //   const isPictureInPictureMode = !!document?.pictureInPictureElement;

  //   return {
  //     isPictureInPictureMode
  //   };
  // };

  const handleClickVideo = async () => {
    // if (toggledStates?.webcamOn) {
    //   const stream = await getVideoTrack({
    //     ...(isDoctor ? doctorCameraConfig : patientCameraConfig),
    //     cameraId: selectedWebcam.id
    //   });
    //   const { isPictureInPictureMode } = getPictureInPictureMode();

    //   if (isUseVirtualBackground(isDoctor)) {
    //     const mediaStream = isPictureInPictureMode ? await processVideoStream(stream) : stream;
    //     toggleWebcam(mediaStream);
    //   } else {
    //     toggleWebcam(stream);
    //   }
    // }
    // if (toggledStates?.micOn) {
    //   toggleMic();
    // }

    if (!isDoctor && isODDConsultation) {
      handleShowAppDrawer({
        checkActive: true,
        callback: handleShowVideo
      });
    } else {
      handleShowVideo();
    }
    tracking.trackEvent('join_room', isDoctor ? 'doctor' : 'patient', {
      micOn: localMicOn,
      webcamOn: localWebcamOn,
      action: 'header_video_button'
    });
  };

  const handleClickInfo = () => {
    setShowInfo(prevCheck => !prevCheck);
  };

  const handleClickHide = () => {
    setShowInfo(false);
  };

  const handleShowVideo = () => {
    setShowVideo(true);
    setShowFullChat(false);
    setShowChat(false);
  };

  const handleSwitchCamera = () => {
    setToggleCamera(!toggleCamera);
    meetingSdk?.changeWebcam();
  };

  const goToAppointmentDetail = () => {
    const params = getQueryParams(window.location);
    sendMessage({
      message: {
        type: EVENT_TYPE_IFRAME.PAGE_NAVIGATION,
        data: {
          type: EVENT_PAGE_NAVIGATION_ITEM.APPOINTMENT_DETAIL
        }
      },
      domain: params?.domain
    });
  };

  const onClickLeave = () =>
    new Promise(resolve => {
      const remoteUser = !isDoctor ? doctor : patient;
      const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');

      const eventEndCall = {
        ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
        format: 'video_end',
        message: 'video_end',
        text: 'video_end',
        time: getFullDatetime,
        sender: !isDoctor ? 'patient' : 'doctor'
      };
      const eventPayload = JSON.stringify(eventEndCall);

      saveChat({
        appointmentNumber: appointmentNumber,
        message: eventEndCall
      });
      publish(eventPayload, { persist: false });

      const leave = () => {
        const isMobile = mobileBreakPoint();
        setShowFullChat(true);
        setShowVideo(false);
        setShowChat(false);
        setShowInfo(!isMobile);
        localMicOn && meeting.toggleMic();
        localWebcamOn && meeting.toggleWebcam();
        setIsUnread(false);
      };

      leave();

      tracking.trackEvent('leave_room', isDoctor ? 'doctor' : 'patient', {
        micOn: useGlobalConfigStore.getState().micOn,
        webcamOn: false,
        action: 'on_leave_room'
      });

      setShowAlert(false);
      sendMessage({ message: { type: 'backSession' } });

      // prevents spams click leave now
      setTimeout(() => {
        resolve(true);
      }, 200);
    });

  useEffect(() => {
    setIsUnread(!showChat && !showFullChat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    videoProcessor.current = new VirtualBackgroundProcessor();
  }, []);

  const handleClickClose = () => {
    setShowAlert(true);
    if (isODDConsultation) {
      setAlertType(isPatientJoinTheCall ? DRAWER_TYPE.CLOSE_APPOINTMENT : DRAWER_TYPE.SCHEDULE_APPOINTMENT);
    } else {
      setAlertType(DRAWER_TYPE.CLOSE_APPOINTMENT);
    }
  };

  return (
    <div className={classNames('chatbox-header-group', { 'chatbox-header-hide': !showHeader })}>
      <audio ref={audioPlayer} src={notificationSound} />

      {/* This component is for video call screen for mobile and all other screens */}
      <Left
        isODDConsultation={isODDConsultation}
        showVideo={showVideo}
        setShowVideo={setShowVideo}
        showBackButton={showBackButton}
        isSessionEnd={isSessionEnd}
        showToggle={showToggle}
        showChat={showChat}
        setShowChat={setShowChat}
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        showFullChat={showFullChat}
        setShowFullChat={setShowFullChat}
        handleClickVideo={handleClickVideo}
        isUnread={isUnread}
        setIsUnread={setIsUnread}
        isMeetingStarted={isMeetingStarted}
        handleSwitchCamera={handleSwitchCamera}
        setShowAlert={val => setShowAlert(val)}
        setAlertType={val => setAlertType(val)}
        t={t}
      />
      {showSideBar && (
        <div className="chatbox-header chatbox-header--sidebar">
          {showChat && !showInfo && (
            <>
              <div className="chatbox-header-menu__info">
                <div className="thumbnail">
                  <Png
                    external={!!recipientPhoto && !isDoctor}
                    name={photo}
                    alt="doctor profile"
                    className="thumbnail__image"
                    width="10px"
                  />
                </div>
                <div className="chatbox-header-menu__info-text">
                  <h6 className="text-truncate">{recipientName}</h6>
                </div>
              </div>
              <div className="chatbox-header-menu__mobile">
                <div className="chatbox-header-menu__mobile-close">
                  <Button onClick={handleClickClose} variant="close"></Button>
                </div>
                <div className="chatbox-header-menu__mobile-info text-bold">{t('Chat')}</div>
                <div className="chatbox-header-menu__mobile-button">
                  <Button disabled={isSessionEnd} onClick={handleClickVideo} variant="video" active={showVideo} />
                  <Button disabled={isSessionEnd} onClick={handleClickInfo} variant="info" active={showInfo} />
                </div>
              </div>
            </>
          )}
          {showInfo && (
            <div className="chatbox-header-menu__mobile">
              <div className="d-flex justify-content-end w-100">
                <button disabled={isSessionEnd} className="button clear-blue" onClick={handleClickHide}>
                  <span className="text-blue" style={{ fontSize: 14, fontWeight: 600 }}>
                    {t('Hide')}
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <AppDrawer
        show={isShowAppDrawer}
        title={t('Video Preview')}
        content={preMeetingScreen}
        height="100vh"
        onHide={() => handleShowAppDrawer({ checkActive: false })}
      />
      <CloseDrawer
        ignoreHideOnClickLeave={alertType === DRAWER_TYPE.CLOSE_APPOINTMENT}
        show={showAlert}
        onHide={() => setShowAlert(false)}
        type={alertType}
        onClickLeave={alertType === DRAWER_TYPE.CLOSE_APPOINTMENT ? goToAppointmentDetail : onClickLeave}
      />
    </div>
  );
};

Header.propTypes = {
  showSideBar: PropTypes.bool,
  showHeader: PropTypes.bool,
  showVideo: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showEndConsultation: PropTypes.bool,
  isSessionEnd: PropTypes.bool,
  showToggle: PropTypes.bool,
  showChat: PropTypes.bool,
  showInfo: PropTypes.bool,
  isDoctor: PropTypes.bool,
  recipientName: PropTypes.string,
  recipientPhoto: PropTypes.string,
  preMeetingScreen: PropTypes.node
};

Header.defaultProps = {
  showSideBar: false,
  showHeader: false,
  showVideo: false,
  showBackButton: false,
  showEndConsultation: false,
  isSessionEnd: false,
  showToggle: false,
  showChat: false,
  showInfo: false
};

export default Header;
