import { useTranslation } from 'react-i18next';
import Button from 'okadoc-component-ui/lib/Button';
import BootstrapModal from 'components/atoms/bootstrap-modal';
import { RecordConfirmationModalGlobalStyle, Title, ButtonContainer } from './styles';

const RecordConfirmationModal = ({ open = false, onHide = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation();

  return (
    <>
      <RecordConfirmationModalGlobalStyle />
      <BootstrapModal className="record-confirmation-modal" show={open} centered>
        <BootstrapModal.Body>
          <Title>{t('You’re about to initiate recording. Are you sure?')}</Title>
          <ButtonContainer>
            <div className="button-confirm">
              <Button onClick={onSubmit} block>
                {t('Confirm')}
              </Button>
            </div>
            <div>
              <Button variant="outline-green" onClick={onHide} block>
                {t('Cancel')}
              </Button>
            </div>
          </ButtonContainer>
        </BootstrapModal.Body>
      </BootstrapModal>
    </>
  );
};

export default RecordConfirmationModal;
