import { useMemo, useState } from 'react';
import { Square, Record } from 'phosphor-react';
import Colors from 'okadoc-component-ui/lib/Colors';
import { Constants, useMeeting } from '@videosdk.live/react-sdk';
import getRecordingConfig from 'libs/recording-config';
import { ToolboxButton } from './styles';
import RecordConfirmationModal from './record-confirmation-modal';

const RecordMeeting = ({ shallRecord = false }) => {
  const [isShowRecordConfirmationModal, setIsShowRecordConfirmationModal] = useState(false);
  const recordingConfig = getRecordingConfig();

  const handleRecordingError = data => {
    const { code } = data;
    if (code === 5001) {
      meeting?.startRecording(null, null, recordingConfig);
    }
  };

  const meeting = useMeeting({
    onerror: handleRecordingError
  });

  const handleClickStartRecording = () => {
    if (!shallRecord) {
      meeting?.startRecording(null, null, recordingConfig);
      handleToggleRecordConfirmationModal();
    }
  };

  const handleClickStopRecording = () => {
    meeting?.stopRecording();
  };

  const isRecording = useMemo(
    () =>
      meeting?.recordingState === Constants.recordingEvents.RECORDING_STARTED ||
      meeting?.recordingState === Constants.recordingEvents.RECORDING_STOPPING,
    [meeting?.recordingState]
  );

  const handleToggleRecordConfirmationModal = () => {
    setIsShowRecordConfirmationModal(value => !value);
  };

  if (!meeting) {
    return null;
  }

  return (
    <div className="toolbox-button-container">
      {isRecording ? (
        <ToolboxButton $isRecording aria-label="Stop Recording" className="toolbox-button" role="button">
          <div className="toolbox-icon">
            <Square
              className="recording-icon"
              onClick={handleClickStopRecording}
              size={24}
              color={Colors.text.WhiteText}
            />
          </div>
        </ToolboxButton>
      ) : (
        <ToolboxButton aria-label="Start Recording" className="toolbox-button" role="button">
          <div className="toolbox-icon">
            <Record
              className="recording-icon"
              onClick={handleToggleRecordConfirmationModal}
              size={24}
              color={Colors.text.WhiteText}
            />
          </div>
        </ToolboxButton>
      )}
      <RecordConfirmationModal
        open={isShowRecordConfirmationModal}
        onHide={handleToggleRecordConfirmationModal}
        onSubmit={handleClickStartRecording}
      />
    </div>
  );
};

export default RecordMeeting;
