import Colors from 'okadoc-component-ui/lib/Colors';
import styled, { createGlobalStyle } from 'styled-components';

export const RecordConfirmationModalGlobalStyle = createGlobalStyle`
  .bootstrap-modal.record-confirmation-modal.modal {
    .modal-body {
      padding: 20px;
    }
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 411px !important;
      }
    }
  }
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${Colors.text.RhinoGrey};
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  .button-confirm {
    margin-bottom: 10px;
  }
`;
